<template>
  <div class="shipments-page">
    <table-filter :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
    <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
      <export-button v-if="localStorage.getItem('multipleWarehouses') === 'true'" class="mr-4 float-left whitespace-nowrap"></export-button>
      <print-label-button class="mr-4 float-left whitespace-nowrap" :selected="selected"></print-label-button>
    </div>
    <shipblu-table
      :sst="true"
      multiple
      orders
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="cashRequests"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('City')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('Request Date')}}</shipblu-th>
        <shipblu-th>{{$t('Col - Attempts')}}</shipblu-th>
        <shipblu-th>{{$t('Col - Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-cash-collections`, params: { type: 'cash-collections', orderID: data[indextr].id }}">
          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerCity">
            {{ $t(data[indextr].customer.address.zone.city.name) }}
          </shipblu-td>
          <shipblu-td :data="data[indextr].customerZone">
            {{ $t(data[indextr].customer.address.zone.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ data[indextr].customer.address.line_1 }} / {{ data[indextr].customer.address.line_2 }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].amount">
          </shipblu-td>
          <shipblu-td :data="data[indextr].amount">
          </shipblu-td>
          <shipblu-td :data="data[indextr].amount">
          </shipblu-td>

          <shipblu-td :style="`color: ${getStyle(data[indextr])}`" :data="data[indextr].status" style="white-space: nowrap;">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <print :selected="selected"></print>
    <block-customer :blockCustomerModal="blockCustomerModal" @blockCustomerModal="blockCustomerModal = $event" :data="customer" @loadShipments="loadCashCollection"></block-customer>
  </div>
</template>

<script>
import Shipment from '../merchant/components/Shipment.vue'
import ExportButton from './components/ExportButton.vue'
import PrintLabelButton from './components/PrintLabelButton.vue'
import Print from '../merchant/components/PrintLabels.vue'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import BlockCustomer from '../admin/components/BlockCustomer.vue'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import TableFilter from '../merchant/components/TableFilter.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      localStorage,
      selected: [],
      cashRequests: [],
      type: '',
      cashCollectionModal: false,
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: '#118C4F'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'Out For Collection',
          value: 'out_for_collection'
        },
        {
          name: 'Collected',
          value: 'collected'
        },
        {
          name: 'Failed to Collect',
          value: 'collection_attempted'
        },
        
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      filters: [],
      searchVal: '',
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      cashData: {},
      cashStatus: '',
      file_URl: '',
      searchInProgress: false,
      searchedValue: ' ',
      blockCustomerModal: false,
      customer: {},
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.selected = []
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-cash-collections-view`,
        query: {
          filter: this.filters,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadCashCollection()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadCashCollection()
      }
    },
    '$route.params.warehouseID' () {
      this.searchVal = ''
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.currentPage = 1
      this.loadCashCollection()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadCashCollection()
    },
    blockCustomer (data) {
      this.customer = data
      this.blockCustomerModal = true
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCashCollectionsSearch)
    },
    loadCashCollectionsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadCashCollection()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-cash-collections-view`,
        query: {
          filter: this.filters,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadCashCollection()
    },
    loadCashCollection () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?status=${this.filters}&offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&warehouse_code=${this.$route.params.warehouseID ? this.$route.params.warehouseID : ''}`
      sendRequest(true, false, this, `api/v1/cash-collections/${query}`, 'get', null, true,
        (response) => {
          this.cashRequests = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    viewCashCollection (type, data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-cash-collections`,
        params: {
          type: 'cash-collections',
          orderID: data.id,
          warehouseID: this.$store.state.AppActiveUser.userRole === 'head-of-fleet' ? this.$route.params.warehouseID : ''
        }
      }).catch(() => {})
    },
    updateCashStatus (data, sentence, status) {
      this.cashData = data
      this.cashStatus = status
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: `Are you sure you want to ${sentence} ?`,
        acceptText: 'Yes',
        accept: this.confirmUpdateCashStatus
      })
    },
    confirmUpdateCashStatus (data, status) {
      const cashCollection = [
        {
          status: status ? status : this.cashStatus,
          cash_collection_request: data ? data.id : this.cashData.id
        }
      ]
      sendRequest(false, false, this, 'api/v1/tracking/cash-collections/', 'post', cashCollection, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Cash Collection'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadCashCollection()
        }
      )
    },
    getInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/merchants/${data.merchant.id}/invoices/?invoicecashcollection__order=${data.id}`, 'get', null, true,
        (response) => {
          if (response.data.results.length > 0) {
            this.downloadInvoice(response.data.results[0].id)
          } else {
            this.$vs.notify({
              color:'warning',
              title:i18nData[this.$i18n.locale]['Warning'],
              text:i18nData[this.$i18n.locale]['Invoice isn\'t ready'],
              position: 'top-center'
            })
          }
        }
      )
    },
    downloadInvoice (invoiceId) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${invoiceId}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    }
  },
  components: {
    ExportButton,
    PrintLabelButton,
    Print,
    Shipment,
    BlockCustomer,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    TableFilter,
    ShipbluPagination
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.loadCashCollection()
  }
}
</script>

<style>
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
